<template>
  <div>
    <!-- popup подтверждающий удаление -->
    <DeletePopupCommon
      title="пользователей"
      description="Вы уверены, что хотите удалить из группы выбранных пользователей?"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteFunction"
    />
    <!-- /popup подтверждающий удаление-->
    <TableFilters
      :title-count="
        this.headerCounter ? this.headerCounter : this.sourceObject.count
      "
      :queries="queries"
      :loading="isDateLoaded"
    >
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="createUserObjects"
          append
          class="btn btn-add btn_color_green"
          >Добавить пользователя</router-link
        >
      </template>
      <template v-if="urlPath != 'kindergartens'" v-slot:delete-button>
        <v-btn
          :disabled="isDisabled"
          color="#EB5C6D"
          class="btn btn-delete btn_color_red"
          @click="clickDelete"
          style="color:white"
        >
          Удалить пользователей
        </v-btn>
      </template>
    </TableFilters>
    <TableLayout
      :loading="isDateLoaded"
      to="usersObject"
      :table-data="sourceObject.results"
      :header="header"
      :source-object="sourceObject"
      v-if="sourceObject.results"
      :deleteAll="urlPath != 'kindergartens'"
    >
      <template v-if="urlPath != 'kindergartens'" slot-scope="{ itemFather }">
        <v-checkbox
          v-bind="$attrs"
          :data-attribute="'delete-checkbox'"
          class="ma-0 pa-0 checkbox-type"
          :value="itemFather.id"
          v-model="deleteCkeckboxList"
          hide-details
        >
        </v-checkbox>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import DeletePopupCommon from "@/components/popup/DeletePopupCommon";
import TableFilters from "@/components/tables/TableFilters";
import tableColsUsersObject from "@/helpers/tableCols/UserObject";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "CompanyUsers",
  data() {
    return {
      loadingBtn: false,
      queries: query.objectUsers.filter(el => el.type !== "is_deleted"),
      dialog: false // диалоговое окно popup удаления
    };
  },
  components: { TableFilters, TableLayout, DeletePopupCommon },
  computed: {
    header() {
      const tempHeader = tableColsUsersObject.filter(
        el =>
          ![
            "Дилеров",
            "Компаний",
            "Объектов",
            "Контроллеров",
            "Карта",
            "Приложение",
            "Тестовый период",
            "Доступные группы пользователей",
            "Выбранная группа пользователей",
            "Номер л/с"
          ].includes(el.text)
      );

      return tempHeader;
    },

    sourceGroup() {
      return this.$store.getters.getGroupInfo;
    },
    headerCounter() {
      return this.sourceGroup.results.title ==
        `Пользователи с бесплатным доступом к объекту "${this.sourceGroup.results.object_details.name}"` &&
        this.sourceObjectFromUsers.results.object_details.type == "kindergarten"
        ? `${this.sourceGroup.results?.user_count} из ${
            this.sourceObjectMaxUsers ? this.sourceObjectMaxUsers : "Ꝏ"
          }`
        : `${
            this.sourceGroup.results?.user_count
              ? this.sourceGroup.results?.user_count
              : "0"
          } из ꝏ`;
    },
    sourceObjectMaxUsers() {
      return this.$store.getters.getDealerInfo.results?.free_participant_limit;
    },
    sourceObject() {
      return this.$store.getters.getPageUsersList;
    },
    sourceObjectFromUsers() {
      return this.$store.getters.getGroupInfo;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    isDisabled() {
      return this.deleteCkeckboxList.length <= 0 ? true : false;
    },
    deleteCkeckboxList: {
      get() {
        return this.$store.getters.getDeleteList;
      },
      set(value) {
        this.$store.commit("UPDATE_DELETE_LIST", value);
        if (this.$store.getters.getCheckedAll.includes("all")) {
          this.$store.commit("SET_CHECKED_ALL", []);
        }
      }
    },
    urlPath() {
      if (this.$route.path.includes("kindergartens")) {
        return "kindergartens";
      } else {
        return "";
      }
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store.dispatch("downloadUsersList", this.header).then(() => {
        this.loadingBtn = false;
      });
    },
    clickDelete() {
      this.dialog = true;
    },
    closeDeletePopup(data) {
      this.dialog = data;
    },
    deleteFunction() {
      let data = {
        users: this.deleteCkeckboxList
      };
      const group_id = this.$store.getters.getGroupInfo.results.id;

      this.$store
        .dispatch("deleteArrUsers", { data, group_id })
        .then(() => {
          this.isDateLoaded = true;
          this.$store.dispatch("fetchPageUsersList").finally(() => {
            this.isDateLoaded = false;
          });
        })
        .then(() => {
          this.$store.dispatch("clearCheckedList");
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.users)
          );
        });
    }
  },
  created() {
    this.$store.dispatch("clearCheckedList");
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, groups: this.urlQuery.id },
      id: this.urlQuery.id
      // type: this.type
    });
    this.isDateLoaded = true;
    this.$store
      .dispatch("fetchPageUsersObjectList", {
        id: localStorage.getItem("ObjectId")
      })
      .finally(() => {
        this.isDateLoaded = false;
      });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            groups: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store
          .dispatch("fetchPageUsersObjectList", {
            id: localStorage.getItem("ObjectId")
          })
          .finally(() => {
            this.isDateLoaded = false;
          });
      }
    }
  }
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch("clearUsersPage").then(() => next());
  // }
};
</script>

<style scoped></style>
