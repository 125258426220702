<template>
  <MainLayout>
    <template v-slot:block v-if="getCurrentRole !== 31">
      {{
        sourceObject.results
          ? sourceObject.results.is_active === false
            ? "Заблокирован -"
            : ""
          : ""
      }}
    </template>
    <template v-slot:navbar-title>{{
      sourceObject.results ? sourceObject.results.title : ""
    }}</template>
    <template v-slot:navbar-btn-wrap>
      <BlockPopup
        v-if="
          sourceObject.results &&
            sourceObject.results.is_active &&
            getCurrentRole !== 31
        "
        name="Группу пользователей"
        :title="sourceObject.results ? sourceObject.results.title : ''"
        :block-function="banUserGroup"
      />
      <UnBlockPopup
        v-if="
          !(sourceObject.results && sourceObject.results.is_active) &&
            getCurrentRole !== 31
        "
        name="Группу пользователей"
        :title="sourceObject.results ? sourceObject.results.title : ''"
        :un-block-function="unBlockUserGroup"
      />
      <DeletePopup
        v-if="getCurrentRole !== 31 && sourceObject.results?.role !== 120"
        name="Группу пользователей"
        :title="sourceObject.results ? sourceObject.results.title : ''"
        :delete-function="deleteUserGroup"
      />

      <router-link
        v-if="urlQuery && urlQuery.id && getCurrentRole !== 31"
        :to="`/userGroupsEdit/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >

      <!--      <button class="btn btn-edit btn_color_grey">редактировать</button>-->
    </template>
    <SlideLayout :tabs="slideTabs">
      <template v-slot:info>
        <div class="keep__title">
          <p class="keep__text">Группы пользователей</p>
          <!--          <button class="btn btn_color_grey btn-edit">Редактировать</button>-->
        </div>
        <BasicTableWithoutHeader
          v-if="sourceObject.results"
          :table-data="getDataRequisites"
        />
      </template>
      <template v-if="!isObject" v-slot:users>
        <UsersGroupUsers />
      </template>
      <template v-else v-slot:users>
        <UsersGroupUsersObjects />
      </template>
    </SlideLayout>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import UsersGroupUsers from "@/components/info/usersGroup/UsersGroupUsers";
import UsersGroupUsersObjects from "@/components/info/usersGroup/UsersGroupUsersObjects";
import DeletePopup from "@/components/popup/DeletePopup";
import BlockPopup from "@/components/popup/BlockPopup";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import UnBlockPopup from "@/components/popup/UnBlockPopup";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "UserGroupsInfosPages",
  components: {
    UnBlockPopup,
    BasicTableWithoutHeader,
    // InfoLayout,
    BlockPopup,
    DeletePopup,
    SlideLayout,
    MainLayout,
    UsersGroupUsers,
    UsersGroupUsersObjects
  },
  data: () => ({
    type: "user_groups",
    infoTabs: [
      {
        text: "Данные группы",
        value: "recv"
      }
    ],
    headers: [
      {
        value: "text",
        text: "text"
      },
      {
        value: "value",
        text: "value"
      }
    ]
  }),
  computed: {
    isObject() {
      return localStorage.getItem("ObjectId");
    },
    getCurrentRole() {
      return this.$store.getters.getCurrentRole;
    },
    slideTabs() {
      return [
        {
          text: "Общая информация",
          value: "info",
          icon: "i"
        },
        {
          text: "Пользователи",
          value: "users",
          count: this?.sourceObject?.results?.user_count,
          icon: null,
          userRole: []
        }
      ];
    },

    sourceObject() {
      return this.$store.getters.getGroupInfo;
    },
    sourceObjectMaxUsers() {
      return this.$store.getters.getDealerInfo.results?.free_participant_limit;
    },
    getDataRequisites() {
      return [
        {
          text: "ID",
          value: this.sourceObject.results.id
        },
        {
          text: "Название",
          value: this.sourceObject.results.title
        },
        {
          text: "Объект",
          value: this.sourceObject.results.object_details
            ? this.sourceObject.results.object_details.name
            : "-"
        },
        !this.routerParams.kindergartenId
          ? {
              text: "Адрес",
              value: this.sourceObject.results.object_details
                ? this.sourceObject.results.object_details.address
                : "-"
            }
          : undefined,
        {
          text: "Пользователей",
          value:
            this.sourceObject.results.appprices_details.length == 0 &&
            this.sourceObject.results.object_details.type == "kindergarten"
              ? `${this.sourceObject.results?.user_count} из ${
                  this.sourceObjectMaxUsers ? this.sourceObjectMaxUsers : "ꝏ"
                }`
              : `${this.sourceObject.results?.user_count} из ꝏ`
          // value: "-"
        },
        {
          text: "Контроллеров",
          value: this.sourceObject?.results?.controllers?.length || "-"
        },
        {
          text: "Тариф",
          value: this.sourceObject?.results?.tariff_name
        },
        // {
        //   text: "Стоимость в день",
        //   value: this.sourceObject.results.appprices_details
        //     ? this.sourceObject.results.appprices_details[0]?.price
        //     : "-"
        // },
        {
          text: "Стоимость за 3 месяца",
          value:
            this.sourceObject.results.appprices_details != 0
              ? // this.sourceObject.results.appprices_details.find(
                //   el => el.duration === 3
                // )?.discount +
                // "% /" +
                this.sourceObject.results.appprices_details.find(
                  el => el.duration === 3
                )?.cost_with_discount + "₽"
              : "-"
        },
        {
          text: "Стоимость за 12 месяцев",
          value:
            this.sourceObject.results.appprices_details != 0
              ? // this.sourceObject.results.appprices_details.find(
                //     el => el.duration === 12
                // )?.discount +
                // "% /" +
                this.sourceObject.results.appprices_details.find(
                  el => el.duration === 12
                )?.cost_with_discount + "₽"
              : "-"
        }
      ].filter(el => !!el && !!el.value);
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    }
  },
  methods: {
    deleteUserGroup() {
      this.$store
        .dispatch("deleteUserGroup", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.userGroup)
          );
        });
    },
    banUserGroup() {
      this.$store
        .dispatch("banUserGroup", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.ban(successMessageDictionary.userGroup)
          );
        });
    },
    unBlockUserGroup() {
      this.$store
        .dispatch("unBlockUserGroup", { id: this.urlQuery.id })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.unBan(successMessageDictionary.userGroup)
          );
        });
    }
  },
  created() {
    this.$store.commit("setSearchQuery", {
      id: this.urlQuery.id,
      query: { ...this.urlQuery.query, type: this.type }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchUserGroupInfo").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.commit("setSearchQuery", {
        id: this.urlQuery.id,
        query: {
          ...to.query
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchUserGroupInfo").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearGroupsPage").then(() => next());
  }
};
</script>

<style scoped></style>
